import 'antd-mobile/es/global';
import './theme.less';
import {NAEventType,sendRecord} from './utils/aws_analytics.js';
import ReactPixel from 'react-facebook-pixel';

const checkAndAddScript = (url,options = {})=>{
        
    return new Promise((resolve,reject)=>{
        const head = document.head
        const scripts = head.getElementsByTagName('script')
        
        const exists = 
        Array.from(scripts)
        .filter(
            script =>
            script.src === url
        ).length > 0;

        if(!exists){
            const script = document.createElement('script')
            script.src  = url;
            script.type = "text/javascript";
            if(options.async){
                script.async = true;
            }
            document.head.appendChild(script)
            script.addEventListener('load', function() {
                resolve();
            });
            script.addEventListener('error', function() {
                reject();
            });
        }else{
            resolve();
        }
    })
}

// import VConsole from 'vconsole';
if (process.env.UMI_ENV != 'production') {
   
    checkAndAddScript("https://cdn.bootcss.com/vConsole/3.3.0/vconsole.min.js")
    .then(()=>{
        const vConsole = new VConsole();
    })
    
} 
if (process.env.UMI_ENV == 'production') {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}

    ReactPixel.init('816908949862251', null, options);
    ReactPixel.pageView();

    // checkAndAddScript("https://cdn.ywxi.net/js/1.js",{async:true})
    // .then(()=>{
    //     console.log('finish add trustedsite')
    // })
}

const options = {
    /* 在此处设置 Facebook Pixel 的初始化选项 */
    autoConfig: true, // 自动配置 Pixel
    debug: false, // 设置为 true 可在开发环境下启用调试模式
};


    // 结束调试后，可移除掉
    // vConsole.destroy(); 
// 或者使用配置参数来初始化，详情见文档

// 接下来即可照常使用 `console` 等方法

export function onRouteChange({ location, routes, action } : any) {
    let recordContent = NAEventType.pageview.content;
    recordContent.action = action;
    sendRecord({
        type:NAEventType.pageview.type,
        content:recordContent
    })
    process.env.UMI_ENV == 'production' && ReactPixel.pageView();
}