import request from '@/utils/request';
import { Toast } from 'antd-mobile';
import qs from 'qs';
import { bytesToBase64, base64ToBytes } from 'byte-base64';
import {getPageQuery} from '@/utils/utils';
import {history} from 'umi';
//获取首页数据
export async function queryHomeData(param) {
  const { data,status } = await request('/api/page/index',{
    method: 'GET',
  });
  // const res = indexPullRes.Msg.deserializeBinary(result);
  // const resObj = fromJS(res.toObject());
  console.log('获取首页数据==', data);
  if(status == 'success'){
    return data;
  }else{
    console.log('error')
  }
}
//获取首页分类列表
export async function queryShopCategory(param) {
  console.log('获取首页分类列表==', param);
  const data = await request('/api/page/index/category/products?category_id='+param,{
    method: 'GET',
  });
  return data.data;
}
//一级分类
export async function queryfirstCategoryList(param) {
  console.log('一级分类==', param);
  const { data,status } = await request('/api/category/level1/list',{
    method: 'POST',
    body:JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  });
  if(status == 'success'){
    return data;
  }else{
    console.log('error')
  }
}

//二级分类页数据
export async function queryCategoryList(param) {
  console.log('分类页数据==', bytesToBase64(param));
  const { data,status } = await request('/api/category/level2/list', {
    method: 'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  });
  if(status == 'success'){
    return data;
  }else{
    console.log('error')
  }
}
//三级分类数据
export async function queryCategoryThreeList(param) {
  const { data,status } = await request('/api/category/level3/list', {
    method: 'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  });

  if(status == 'success'){
    return data;
  }else{
    console.log('error')
  }
}
//获取分类列表
export async function queryCategoryListData(param) {
  const data = await request('/api/category/list', {
    method: 'GET',
  });
  return data;
}
//产品详情页数据
export async function queryDetailData(param) {
 
  const result = await request('/api/product/detail', {
    method: 'POST',
    body:JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
    });
  return result;
}
//详情下面description
export async function queryDescription(param) {
 
  const result = await request('/api/product/detail/description', {
    method: 'POST',
    body:JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
    });
  return result;
}

//获取注册coupon类型
export async function getTenCouponDetail(param) {
  const data = await request('/api/activity/getTenCouponDetail', {
    method: 'GET',
  });
  return data;
}
export async function getCouponDetail(param) {
  const data = await request('/api/activity/detail', {
    method: 'POST',
    body:JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  });
  return data;
}

//用户注册
export async function createUser(param) {
  param.firstName = 'han'
  param.lastName = 'zhang'
  const result = await request('/api/page/sign_up/register', {
    method: 'POST',
    body:JSON.stringify(param),
    headers:{
      'Content-Type': 'application/json',
    }
  });
  return result;
}

//用户登录
export async function userLogin(param) {
  const response = await request('/passport/local/login', {
    method: 'POST',
    body:JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  });
  
  return response;
}
// 登录/注册-手机发送验证码
export async function passportSendSMS(param){
  const response = await request('/passport/send-sms',{
    method:'POST',
    body:JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  });

  return response;
}
// 登录/注册- 校验验证码
export async function verifySMSCodeService(param){
  const response = await request('/passport/verify-sms-code',{
    method:'POST',
    body:JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  });

  return response;
}

//快速登陆
export async function verifySMSCodeServiceQuickLogin(param){
  const response = await request('/passport/verify-sms-to-sign-in-sign-up',{
    method:'POST',
    body:JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  });

  return response;
} 

// 登录/注册 - 手机号注册
export async function registerByPhone(param){
  const response = await request('/passport/phone/register',{
    method:'POST',
    body:JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  });

  return response;
}

// 登录/注册 - email
export async function passportEmail(param){
  const response = await request('/passport/email',{
    method:'POST',
    body:JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  });

  return response;
}

// 登录/注册 - Email注册
export async function registerByEmail(param){
  const response = await request('passport/email/register',{
    method:'POST',
    body:JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  });

  return response;
}

//用户名是否重复
export async function userNameRepeat(param) {
  const response = await request('/api/page/sign_up/check_for_duplicate_username', {
    method: 'POST',
    body:JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  });
  return response;
}

//用户是否登录
export async function checkLogin() {
  const result = await request('/api/user_check_login.php',{})
  return result;
}
//退出登录
export async function logout() {
  const result = await request('/passport/log_out',{
    method: 'POST',
  })
  return result;
}
//添加购物车
export async function addCart(param) {
  const res = await request('/api/cart/product/add', {
    method: 'POST',
    body:JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  });
  return res;
}

//购物车列表
export async function cartDetail() {
  const result = await request('/api/cart/detail',{
    method: 'GET',
  })
  if(result.status == 'success'){

    return result.data;
  }else{
    console.log('error')
  }
}
//删除购物车商品
export async function delProduct(param) {
  const result = await request('/api/cart/product/delete',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}
//替换购物车商品
export async function changeProduct(param) {
  const result = await request('/api/cart/product/change',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}
//更新购物车商品数量
export async function updateProduct(param) {
  const result = await request('/api/cart/product/update_quantity',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}
//退回商品
export async function returnProduct(param) {
  const result = await request('/api/order/return/docDetail',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}

//提交退货申请
export async function returnSubmit(param) {
  const result = await request('/api/order/return/submit',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}

//新建地址
export async function suAddress(param) {
  let path = '/api/profile/address';
  let method = 'POST';
  if(param.id){
    path = path +'/'+param.id;
    method = 'PUT';
  }
  const result = await request(path, {
    method: method,
    body:JSON.stringify(param.address),
    headers:{
      'Content-Type':'application/json'
    }
  });
  return result;
}

//地址列表
export async function getAddressList(param) {
  const result = await request('/api/profile/address/list?page_size='+encodeURIComponent(param.pageSize)+'&offset='+encodeURIComponent(param.offset),{
    method:'GET'
  });
  return result;
}

//切换地址
export async function changeAddress(param) {
  const  result = await request(`/api/cart/address/change`,{
    method:'POST',
    body: JSON.stringify({locationId:param}),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result
}
//修改地址
export async function addChangeAddress(param) {
  const  result = await request(`/api/profile/address/${param.id}`,{
    method:'PUT',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result
}
//获取地址detail

export async function getEditAddress(id) {
  const result = await request(`/api/profile/address/${encodeURIComponent(id)}/edit`,{
    method:'GET'
  })
  return  result
}
//删除地址 
export async function deleteAddress(id) {
  const result = await request(`/api/profile/address/${encodeURIComponent(id)}`,{
    method:'DELETE',
    headers:{
      'Content-Type':'application/json'
    }
  })
  console.log(result);
  return typeof result == 'string' ? JSON.parse(result) :result ;
}

//校验zipCode
export async function checkZipCode(param) {
  const result = await request(`/api/profile/address/checkZipCode?zip_code=${encodeURIComponent(param.zipCode)}&support_area=${encodeURIComponent(param.supportArea)}&stateId=${encodeURIComponent(param.stateId)}`,{
    method:'GET'
  })
  return result;
}
export async function getUSstate() {
  const result = await request(`/api/profile/state/list`,{
    method:'GET'
  })
  return result;
}
//地址信息
export async function company_locations(param) {
  const data = await request('/api/company_locations.php',{
    method:'POST',
    body: qs.stringify({ids:JSON.stringify(param)}),
  })
  const result = JSON.parse(data['locations']);
  return result;
}

//信用卡
export async function card_payment_sources(param) {
  const data = await request('/api/card_payment_sources.php',{
    method:'POST',
  })
  return data;
}

//获取提交订单数据
export async function checkOrder(param) {
  const path = 'coupon_code=' + encodeURIComponent(param.coupon_code||'') + '&billing_address_id=' + encodeURIComponent(param.billing_address_id||'') + '&credit=' + encodeURIComponent(param.credit||'') 
  + '&address_id=' + encodeURIComponent(param.address_id||'') + '&card_info=' + encodeURIComponent(param.card_info||'') + '&buyer_email=' + encodeURIComponent(param.buyer_email||'') +'&pay_method='+encodeURIComponent(param.pay_method||'')
  + '&add_card_info=' + encodeURIComponent(param.add_card_info||'') + '&address=' + encodeURIComponent(param.address||'')

  const path1='pay_method='+ encodeURIComponent(param.pay_method||'')

  const data = await request(
    param.coupon_code || param.billing_address_id || param.credit || param.address_id || param.card_info || param.buyer_email || param.add_card_info || param.address?
    '/api/cart/checkout/pull?' + path
    :
    '/api/cart/checkout/pull?' + path1,
    {
      method:'GET',
    }
  )
  return data;
}
//提交订单
export async function submitOrder(param) {
  console.log('submitOrder:',param)
  const data = await request('/api/cart/checkout?sub='+JSON.stringify(param),{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return data;
}
//获取订单结果
export async function getOrderResult(param) {
  const data = await request('/api/order/thanks/detail',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return data;
}

//获取收藏列表
export async function getCollectList(param) {
  const data = await request('/api/product/favorite/list' ,{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return data;
}
//删除收藏
export async function delCollect(param) {
  const data = await request('/api/product/favorite/update',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return data;
}
//增加收藏
export async function addCollect(param) {
  const data = await request('/api/product/favorite/create',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return data;
}
//再次购买列表
export async function getBuyAgainList(param) {
  const data = await request('/api/product/buyagain/list',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return data;
}
//获取用户信息
export async function getUserInfo(param) {
  const data = await request('/api/profile/user/info',{
    method:'GET',
  })
  return data;
}
//获取修改页信息
export async function getEditInfo(param) {
  const data = await request('/api/profile/user/editDetail',{
    method:'GET',
  })
  return data;
}

//修改用户信息
export async function editUserProfile(param) {
  const data = await request('/api/profile/user/edit',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return data;
}
//orderHistory列表
export async function orderHistory(param) {
  const result = await request('/api/order/list',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}
//订单详情
export async function orderDetail(param) {
  console.log('订单详情==', param)
  const result = await request('/api/order/detail',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  console.log ('订单详情返回数据==', result);
  return result;
}
//ship详情
export async function orderShipDetail(param) {
  const result = await request('/api/order/shipment/detail',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}


export async function orderThanksDetail(param) {
  console.log('订单thanks详情==', param)
  const result = await request('/api/order/thanks/detail',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  console.log ('orderThanksDetail返回数据==', result);
  return result;
}

export async function zipcodePull(param) {
  console.log('订单thanks详情==', param)
  const result = await request('/api/page/zipcode',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  console.log ('zipcodePull返回数据==', result);
  return result;
}

  
export async function zipCodePush(param) {
  console.log('zipcodePush==', param)
  const result = await request('/api/page/zipcode/zipCodePush',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  console.log ('zipcodePull返回数据==', result);
  return result;
}

//登录信息
export async function getloginInfo(param) {
  const data = await request('/api/profile/user/homePage',{
    method:'GET',
  })
  return data;
}
//新增信用卡
export async function addPayment(param) {
  console.log(param)
  const result = await request('/api/profile/creditCard/add',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  console.log ('订单详情返回数据==', result);
  return result;
}
//payment列表
export async function profile_pull_payment_options(param) {
  const data = await request('/api/profile/creditCard/list',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  const result = data.data.cardList
  return result;
}
//payment删除
export async function paymentdelete(param) {
  const data = await request('/api/profile/creditCard/delete',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  const result = data
  return result;
}

//密码重置email
export async function resetPwdReq(param) {
  const response = await request('/api/page/resetPwdReq', {
    method: 'POST',
    body:JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  });
  return response;
}

//密码重置
export async function resetPwd(param) {
  const response = await request('/api/page/resetPwd', {
    method: 'POST',
    body:JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  });
  return response;
}

//搜索接口
export async function search(param) {
  const response = await request('/api/product/search', {
    method: 'POST',
    body:JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  });
  return response;
}

//获取搜索推荐接口
export async function fetchHot(param) {
  const data = await request('/api/product/fetchHot',{
    method:'GET',
  })
  return data;
}

//取消订单
export async function cancelOrder(param) {
  const response = await request('/api/order/cancel', {
    method: 'POST',
    body:JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  });
  return response;
}

// ---------------- new payment start 

export async function pullBuyerExistsAuPaymentProfile(){
  
  const response = await request('/api/auth-payment/exists-card', {
    method: 'GET',
    headers:{
      'Content-Type':'application/json'
    }
  });
  
  return response;
}

export async function saveAuthorizeCard(params){
  const response = await request('/api/auth-payment/save-card', {
    method: 'POST',
    body:JSON.stringify(params),
    headers:{
      'Content-Type':'application/json'
    }
  });
  
  return response;
}

export async function pullAuthorizePayments(){
  const response = await request('/api/auth-payment/list', {
    method: 'GET',
    headers:{
      'Content-Type':'application/json'
    }
  });
  
  return response;
}

export async function setDefaultPayment(params){
  const response = await request('/api/auth-payment/set-default-card', {
    method: 'POST',
    body:JSON.stringify(params),
    headers:{
      'Content-Type':'application/json'
    }
  });
  
  return response;
}

export async function deletePaymentApi(params){
  const response = await request('/api/auth-payment/delete-card', {
    method: 'POST',
    body:JSON.stringify(params),
    headers:{
      'Content-Type':'application/json'
    }
  });
  
  return response;
}

// ---------------- new payment end


export async function closeNotic(param) {
  console.log(param)
  const result = await request('/api/announcement/mark-read',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}

//邀请注册 .发送验证码
export async function sendsms(param) {
  console.log(param)
  const result = await request('/api/passport/send-sms',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}

//邀请注册 .新用户注册发送10美元 
export async function registerForCoupon(param) {
  console.log(param)
  const result = await request('/api/activity/registerForCoupon',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}

export function creationPaypalOrder(params){
  return request('/api/cart/checkout/create-paypal-order',{
    method:'POST',
    body: JSON.stringify(params),
    headers:{
      'Content-Type':'application/json'
    }
  });
}

export function capturePaypalOrder(params){
  return request('/api/paypal/capture-order',{
    method:'POST',
    body: JSON.stringify({...params}),
    headers:{
      'Content-Type':'application/json'
    }
  });
}
//查询活动
export async function qureyDetection(param) {
  console.log(param)
  const result = await request('/api/page/index/activity/detection',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}
/**
 * coupon page 查询接口
 */
 export async function qureyCouponList(param) {
  console.log(param)
  const result = await request('/api/shop/coupon/queryCouponListOfSpecifiedUser',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}

/**
 * 活动注册
 */
export async function registerForActivity(param) {
  console.log(param)
  const result = await request('/api/activity/user/register',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}

export async function freeGiftDetail(param) {
  console.log(param)
  const result = await request('/api/activity/freebe/detail',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}


export async function freeGiftCheckout(param) {
  console.log(param)
  const result = await request('/api/activity/freebe/checkout',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}

export async function pullInstagramData() {
  const result = await request('/api/page/instagram/get-data',{
    method:'GET',
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}

export async function generateInviteLink() {
  const result = await request('/api/activity/generateInviteLink',{
    method:'GET',
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}

export async function getInviteHistory() {
  const result = await request('/api/activity/getInviteHistory',{
    method:'GET',
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}


export async function recommendDetail() {
  const result = await request('/api/activity/free-gift/recommend/detail',{
    method:'GET',
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}

export async function getInviterName(param) {
  console.log(param)
  const result = await request('/api/activity/getInviterName',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}

export async function recommendTip(param) {
  console.log(param)
  const result = await request('/api/activity/recommendTip/add',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}

// 获取 apple pay merchant session 
export async function getApplePayMerchantSession(param) {
  const data = await request('/api/apple-get-merchant-session?url='+decodeURIComponent(param.url),{
    method:'GET',
    headers:{
      'Content-Type':'application/json'
    }
  })
  return data.data.merchantSession;
}

///category/level1/allList
export async function getAllList(param) {
  console.log(param)
  const result = await request('/api/category/level1/allList',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}

export async function pullThemeAllData(param) {
  console.log(param)
  const result = await request('/api/activity/theme/pullThemeAllData',{
    method:'POST',
    body: JSON.stringify(param),
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}

export async function verifyLogin() {
  const result = await request('/api/passport/verifyLogin',{
    method:'GET',
    headers:{
      'Content-Type':'application/json'
    }
  })
  return result;
}

export async function querylivingHomeData(param) {
  const { data,status } = await request('/api/page/living/index',{
    method: 'GET',
  });
  // const res = indexPullRes.Msg.deserializeBinary(result);
  // const resObj = fromJS(res.toObject());
  console.log('获取living首页数据==', data);
  if(status == 'success'){
    return data;
  }else{
    console.log('error')
  }
}